// Importing components and necessary files.
import AppNameLogo from '../../small-components/app-name-logo/app-name-logo';
import LargeButton from '../../small-components/large-button/large-button';
import ExitButton from '../../small-components/exit-button/exit-button';
import AccountWindow from '../account-window/account-window';
import SideMenu from '../../small-components/side-menu/side-menu';
import CalendarIcon from '../../small-components/large-button/img/calendar-icon.png';
import InventoryIcon from '../../small-components/large-button/img/inventory-icon.png';
import { useNavigate } from 'react-router-dom';
import './start-page.css';

/**
 * StartPage component.
 * This components represents the start page of the application.
 * 
 * @returns {JSX.Element} The rendered start page component.
 */
function StartPage() {
  // Check if the app is running in an Electron environment.
  const isElectron = /Electron/.test(navigator.userAgent);

  // Hook for navigating to different pages.
  const navigate = useNavigate();

  return (
    <div className="start-page">
      {/* Display the application's logo. */}
      <AppNameLogo />

      {/* Display the side menu. */}
      <SideMenu />

      {/* Container for the large buttons. */}
      <div className="button-container">

        {/* Large button for the calendar feature. */}
        <LargeButton onClick={() => navigate('/calendar')}>
          <img src={CalendarIcon} alt="Calendar" />
        </LargeButton>

        {/* Large button for the storage manager feature. */}
        <LargeButton onClick={() => navigate('/Inventory')}>
          <img src={InventoryIcon} alt="Inventory" />
        </LargeButton>
      </div>

      {/* If the app is running in Electron, display the exit button. */}
      {isElectron && (
        <div className="exit-button-container">
          <ExitButton onClick={() => console.log('clicked')} />
        </div>
      )}

      {/* Display the account window. */}
      <AccountWindow />

      {/* Footer. */}
      <div className="footer">
        {/* Footer content here. */}
      </div>
    </div>
  );
}

export default StartPage;