// Imports.
import './large-button.css';

/**
 * LargeButton component.
 * This component represents a large button in the application.
 *
 * @param {Function} onClick - The onClick function for the button.
 * @param {JSX.Element} children - The children of the button, could be text, images or other components seen on the button.
 * @returns {JSX.Element} The rendered LargeButton component.
 */
function LargeButton({ onClick, children }) {
  return (
    // The large button.
    <button className="large-button" onClick={onClick}>
      {children}
    </button>
  );
}
  
  export default LargeButton;