//Imports.
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './side-menu.css';

/**
 * The side menu component.
 *
 * This component represents a side menu that can be toggled open and closed.
 * @returns {JSX.Element}
 */
function SideMenu() {
  // State to manage whether the side menu is open or not.
  const [isOpen, setIsOpen] = useState(false);

  // Function to handle the opening and closing of the side menu.
  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="side-menu">
      {/* Button to open/close the side menu. */}
      <button className='side-menu-button' onClick={handleMenuToggle}></button>

      {/* Overlay that appears when the side menu is open. Clicking on it closes the side menu. */}
      <div className={`side-menu-overlay ${isOpen ? 'open' : ''}`} onClick={handleMenuToggle} />

      {/* The actual side menu content. */}
      <div className={`side-menu-content ${isOpen ? 'open' : ''}`}>
        <h1>Menu</h1>
        {/* Side menu items */}
        <Link to="/" onClick={handleMenuToggle}>Start Page</Link>
        <Link to="/calendar" onClick={handleMenuToggle}>Calendar</Link>
        <Link to="/inventory" onClick={handleMenuToggle}>Inventory</Link>
      <button className='side-menu-button-inside' onClick={handleMenuToggle}></button>
      </div>
    </div>
  );
}

export default SideMenu;