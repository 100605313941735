//Imports.
import React, { useState } from 'react';
import './account-window.css';
import LoginForm from '../../small-components/login-form/login-form';
import CreateAccountForm from '../../small-components/create-account-form/create-account-form';
import RecoverAccountForm from '../../small-components/recover-account-form/recover-account-form';
import LoggedInForm from '../../small-components/logged-in-form/logged-in-form';
import DeleteAccountForm from '../../small-components/delete-account-form/delete-account-form';

/**
 * This component represents the status button, showing if a user is logged in or not.
 * It also displays a menu for logging in, creating an account and to recover an account.
 *
 * @returns {JSX.Element}
 */
function AccountWindow() {
  // State for controlling the visibility of the menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // State for storing the username of the logged in user
  const [username, setUsername] = useState(null);
  // State for storing the form type (login, create account, recover account)
  const [formType, setFormType] = useState('login');
  // State for storing the delete account confirmation.
  const [isDeleteAccountConfirmationOpen, setIsDeleteAccountConfirmationOpen] = useState(false);

  /**
   * Toggles the visibility of the menu.
   */
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

   /**
 * Handles the login action.
 *
 * @param {Object} data - The data from the form.
 * @param {string} data.username - The username of the user.
 */
  const handleLogin = (username) => {
    setUsername(username);
    setIsMenuOpen(false);
  };

  /**
   * Handles the create account action.
   */
  const handleCreateAccount = () => {
    setFormType('createAccount');
  };

  /**
   * Handles the recover account action.
   */
  const handleRecoverAccount = () => {
    setFormType('recoverAccount');
  };

  /**
 * Handles the logout action.
 */
const handleLogout = async () => {
  const response = await fetch('/api/logout', {
    method: 'POST',
    credentials: 'include',
  });

  if (response.ok) {
    setUsername(null);
    setIsMenuOpen(false);
  } else {
    console.error('Failed to log out');
  }
};

  /**
   * Handles the delete account action.
   * Opens the delete account confirmation form.
   */
  const handleDeleteAccount = () => {
    setIsDeleteAccountConfirmationOpen(true);
  };

  /**
   * Handles the confirm delete account action.
   *
   * @param {Object} data - The data from the form.
   * @param {string} data.username - The username of the user.
   * @param {string} data.password - The password of the user.
   */
  const handleConfirmDeleteAccount = ({ username, password }) => {
    setUsername(null);
    setIsMenuOpen(false);
    setIsDeleteAccountConfirmationOpen(false);
  };

  /**
   * Handles the cancel delete account action.
   * Closes the delete account confirmation form.
   */
  const handleCancelDeleteAccount = () => {
    setIsDeleteAccountConfirmationOpen(false);
  };

  /**
   * Handles the back action.
   */
  const handleBack = () => {
    setFormType('login');
  };

  return (
    <div className="account-window">
      {/* Account button. On click, it toggles the visibility of the menu. */}
      <button className='account-button' onClick={toggleMenu}>
        <span className="status-dot"></span>
        {username ? username : 'Not logged in'}
      </button>
      {/* Overlay that appears when the menu is open. On click, it closes the menu. */}
      <div className={`account-overlay ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu} />
      {/* Account content that appears when the menu is open. */}
      <div className={`account-content ${isMenuOpen ? 'open' : ''}`}>
        {/* Pass handleLogin, handleCreateAccount, and handleRecoverAccount to LoginForm. */}
        {isMenuOpen && (
          username ?
          <LoggedInForm onLogout={handleLogout} onDeleteAccount={handleDeleteAccount} /> :
          formType === 'login' ? 
          <LoginForm onLogin={handleLogin} onCreateAccount={handleCreateAccount} onForgot={handleRecoverAccount} /> :
          formType === 'createAccount' ? 
          <CreateAccountForm onBack={handleBack} /> :
          <RecoverAccountForm onBack={handleBack} />
        )}
        {/* Show the delete account confirmation form when needed. */}
        {isDeleteAccountConfirmationOpen && (
          <DeleteAccountForm onConfirm={handleConfirmDeleteAccount} onCancel={handleCancelDeleteAccount} />
        )}
        {/* Close button for the account content. On click, it closes the menu. */}
        <button className="account-close" onClick={toggleMenu}></button>
      </div>
    </div>
  );
}

export default AccountWindow;