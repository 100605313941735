//Imports.
import React, { useState } from 'react';
import './recover-account-form.css';

/**
 * RecoverAccountForm is a React component for a form that allows users to recover their account.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.onBack - The function to call when the user wants to go back.
 */
function RecoverAccountForm({ onBack }) {
   // State variable for the email input field.
  const [email, setEmail] = useState('');

   /**
   * Handles the form submission.
   *
   * @param {Event} event - The form submission event.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email');
      return;
    }

    // Send email to the server
    try {
      const response = await fetch('URL here.', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          recoveryEmail: email
        })
      });
    
      if (!response.ok) {
        throw new Error('Failed to send recovery email');
      }
    } catch (error) {
      alert(error.message);
      return;
    }

    // If everything is ok, notify user.
    alert('Recovery email sent. Please check your inbox.');
    onBack();
  };

  // Render the form.
  return (
    <form onSubmit={handleSubmit}>
      <label className="emailLable">
        Email:
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
      </label>
      <button type="submit">Send recovery email</button>
      <button type="button" onClick={onBack}>Back</button>
    </form>
  );
}

export default RecoverAccountForm;