// Imports.
import React, { useState } from 'react';
import './delete-account-form.css';

/**
 * DeleteAccountForm component.
 * This component is a form that allows a user to delete their account.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.onConfirm - Function to call when the form is submitted.
 * @param {Function} props.onCancel - Function to call when the cancel button is clicked.
 */
function DeleteAccountForm({ onConfirm, onCancel }) {
  // State for the username and password fields.
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  /**
   * Handles the form submission.
   * This function sends a DELETE request to the server to delete the user's account.
   *
   * @param {Event} event - The form submission event.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Send a DELETE request to the server.
    const response = await fetch('/api/users', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    });
  
    if (response.ok) {
      onConfirm();
    } else {
      // Handle error
      console.error('Failed to delete account');
      window.alert('Failed to delete account. Please try again.');
    }
  };

  return (
    <div className="delete-account-form-overlay">
      <form className="delete-account-form" onSubmit={handleSubmit}>
        <h2>Please enter your username and password to confirm.</h2>
        {/* Username field */}
        <label>
          Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
        </label>
        {/* Password field */}
        <label>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </label>
        {/* Confirm button */}
        <button type="submit">Confirm</button>
        {/* Cancel button */}
        <button type="button" onClick={onCancel}>Cancel</button>
      </form>
    </div>
  );
}

export default DeleteAccountForm;