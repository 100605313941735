// Imports
import React from 'react';
import './logged-in-form.css';

/**
 * This component represents the form for logged in users.
 * It allows the user to log out or delete the account.
 *
 * @param {Function} props.onLogout - The function to call when the user logs out.
 * @param {Function} props.onDeleteAccount - The function to call when the user deletes the account.
 * @returns {JSX.Element}
 */
function LoggedInForm({ onLogout, onDeleteAccount }) {
  return (
    <div className="logged-in-form">
      <button onClick={onLogout}>Log Out</button>
      <button onClick={onDeleteAccount}>Delete Account</button>
    </div>
  );
}

export default LoggedInForm;