// Imports.
import './app-name-logo.css';

/**
 * AppNameLogo component.
 * This component represents the application's name logo.
 *
 * @returns {JSX.Element} The rendered AppNameLogo component.
 */
function AppNameLogo() {
  return (
    // Container for the application's name logo.
    <div className="app-name-logo">
      <h1 className="name">Small-scale-organizing</h1>
    </div>
  );
}

export default AppNameLogo;