// Imports.
import React, { useState } from 'react';
import './inventory-item.css';

/**
 * InventoryItem component. This component displays an individual inventory item and provides
 * functionality to update the item's properties, add and subtract stock, and remove the item.
 * 
 * @param {object} props - The properties passed to this component.
 * @param {object} props.item - The inventory item.
 * @param {function} props.onRemove - The function to call when the remove button is clicked.
 */
function InventoryItem({ item, onRemove }) {
  // State for the item's properties.
  const [name, setName] = useState(item.name);
  const [serialNumber, setSerialNumber] = useState(item.serialNumber);
  const [stock, setStock] = useState(item.stock);
  const [pricePerUnit, setPricePerUnit] = useState(item.pricePerUnit);
  const [description, setDescription] = useState(item.description || '');

  // Calculate the total value of the item
  const totalValue = stock * pricePerUnit;

  /**
   * Handler for adding stock to the item.
   */
  const handleAddStock = () => {
    setStock(stock + 1);
  };

  /**
   * Handler for subtracting stock from the item.
   */
  const handleSubtractStock = () => {
    if (stock > 0) {
      setStock(stock - 1);
    }
  };

  return (
    <div className="inventory-item">
      {/* Input fields for the item's properties. */}
      <input className="name-input" type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Name" />
      <input className="serialNumber" type="text" value={serialNumber} onChange={e => setSerialNumber(e.target.value)} placeholder="Serial Number" />
      <textarea className="description" value={description} onChange={e => setDescription(e.target.value)} placeholder="Description" />
      <input className="stock" type="number" value={stock} onChange={e => setStock(Number(e.target.value))} placeholder="Stock" />
      <input className="pricePerUnit" type="number" value={pricePerUnit} onChange={e => setPricePerUnit(Number(e.target.value))} placeholder="Price per Unit" />

      {/* Buttons for adding and subtracting stock and removing the item. */}
      <button onClick={handleAddStock}>Add Stock</button>
      <button onClick={handleSubtractStock}>Subtract Stock</button>
      <button onClick={onRemove}>Remove Item</button>

      {/* Display the total value of the item. */}
      <p className="total-value">Total Value: {totalValue}</p>
    </div>
  );
}

export default InventoryItem;