// Importing components and necessary files.
import SideMenu from '../../small-components/side-menu/side-menu';
import AccountWindow from '../account-window/account-window';
import InventoryList from '../../small-components/inventory-list/inventory-list';
import './inventory-page.css';

/**
 * InventoryPage component.
 * This components represents the inventory page of the application.
 *
 * @returns {JSX.Element} The rendered inventory page component.
 */
function InventoryPage() {
  return (
    <div className="inventory-page">

      {/* Display the side menu. */}
      <SideMenu />

      {/* Display the account window. */}
      <AccountWindow />

      {/* Display the inventory list. */}
      <InventoryList />

      {/* Footer. */}
      <div className="footer">
        {/* Footer content here. */}
      </div>
    </div>
  );
}

export default InventoryPage;