// Imports.
import './exit-button.css';


/**
 * ExitButton component.
 * This component represents the exit button of the application.
 *
 * @param {Function} onClick - The onClick function for the button.
 * @returns {JSX.Element} The rendered ExitButton component.
 */
function ExitButton({ onClick }) {
  /**
   * Handles the click event for the exit button.
   * If it is called the Electron application is quit.
   */
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    // Quit the Electron application.
    window.electron.quitApp();
  };
  return (
    // The exit button.
    <button className="exit-button" onClick={handleClick}>
      Exit
    </button>
  );
}
  
  export default ExitButton;